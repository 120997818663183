<template>
    <AppLayout>
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h2 class="text-h3 font-weight-light">Start your free trial</h2>
            </v-col>
        </v-row>
        <v-row justify="center" class="mb-2">
            <v-col style="text-align: center">
                <p class="text-body-1 font-weight-light">This will be quick. Credit card is <strong>NOT</strong> required.</p>
                <!-- XXX TODO TEMPORARY DEBUG -->
                <!-- <pre>
isViewReady: {{ isViewReady }}
isAuthenticatedReady: {{ isAuthenticatedReady }}
isAuthenticated: {{ isAuthenticated }}
isOptinReady: {{ isOptinReady }}
isInteractionReady: {{ isInteractionReady }}
isVerified: {{ isVerified }}
isVerificationPending: {{ isVerificationPending }}
error: {{ error }}
isVerified: {{ isVerified }}
isLoginRequired: {{ isLoginRequired }}
isRegisterQuickUserPending: {{ isRegisterQuickUserPending }}
                </pre> -->
            </v-col>
        </v-row>
        <!-- <QuickStartSteps :highlight="highlight" :current="step"/> -->

        <!-- email verification form; this one uses v-show instead of v-if because of $refs.nameInput.focus -->
        <v-row justify="center" class="my-0 pt-0 pb-10" v-show="isViewReady && !isAuthenticated && !isVerified && !isVerificationPending && !error">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card elevation="12" class="pa-5">
                    <!-- v-model="verificationForm" ref="verificationFormRef" -->
                    <!--
                        :rules="nameRules"
                        validate-on-blur
                    -->
                    <v-form @submit="validateVerificationForm" onSubmit="return false;" @keyup.enter.native="validateVerificationForm">
                        <p>We need this information to get started:</p>
                        <v-text-field
                        ref="nameInput"
                        v-model="name"
                        label="Name (first name, nickname, or alias)"
                        required
                        outlined
                        hint="What should we call you?"
                        type="text">
                            <template v-slot:prepend>
                                <font-awesome-icon icon="user" fixed-width class="mt-1"/>
                            </template>
                        </v-text-field>
                        <!--
                        :rules="emailRules"
                        validate-on-blur
                        -->
                        <v-text-field
                        ref="emailInput"
                        v-model="email"
                        label="Email"
                        required
                        outlined
                        hint="What email address can we use to reach you?"
                        type="text">
                            <template v-slot:prepend>
                                <font-awesome-icon icon="envelope" fixed-width class="mt-1"/>
                            </template>
                        </v-text-field>
                        <!-- <v-text-field
                        ref="phoneInput"
                        v-model="phone"
                        label="Phone"
                        :rules="phoneRules"
                        validate-on-blur
                        required
                        outlined
                        hint="What telephone number we can use to reach you?">
                        <template v-slot:prepend>
                            <font-awesome-icon icon="phone" fixed-width class="mt-1"/>
                        </template>
                        </v-text-field> -->
                        <v-checkbox
                            ref="checkboxInput"
                            v-model="isAgreeToTermsChecked"
                            color="blue"
                            required
                            class="mt-0 pt-0">
                            <template v-slot:label>
                                <div>
                                I agree to the
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                    <a
                                        target="_blank"
                                        href="/about/terms"
                                        @click.stop
                                        v-on="on"
                                    >Terms of Use</a>
                                    </template>
                                    Opens in new window
                                </v-tooltip>
                                and
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                    <a
                                        target="_blank"
                                        href="/about/privacy"
                                        @click.stop
                                        v-on="on"
                                    >Privacy Policy</a>
                                    </template>
                                    Opens in new window
                                </v-tooltip>
                                </div>
                            </template>
                        </v-checkbox>
                        <v-row justify="center">
                            <v-card-actions>
                                <v-btn elevation="4" @click="onSubmitVerification()" :disabled="!isVerificationFormComplete" class="blue white--text">
                                    <span>Continue</span>
                                </v-btn>
                            </v-card-actions>
                        </v-row>
                    </v-form>
                </v-card>
                <p class="text-center my-5">Already have a LoginShield account? <a @click='onLoginClicked'>Login</a></p>
            </v-col>
        </v-row>

        <v-row justify="center" v-if="isViewReady && !isAuthenticated && !isVerified && isVerificationPending && !error" class="my-0 pt-0 pb-10">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="!isVerificationReady">
                <p class="text-h5 font-weight-light py-5 text-center">Sending verification email...</p>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="isVerificationReady">
                <h1 class="text-h6 font-weight-light py-5 text-center">Check your inbox.</h1>

                <v-expansion-panels accordion multiple class="my-5">
                    <v-expansion-panel style="border-radius: 0px;">
                        <v-expansion-panel-header class="text-overline">What is the next step?</v-expansion-panel-header>
                        <!-- <v-divider></v-divider> -->
                        <v-expansion-panel-content class="py-3">
                            <p class="text-body-1 font-weight-light">Look for our email and follow the link to continue.</p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel style="border-radius: 0px;">
                        <v-expansion-panel-header class="text-overline">I didn't receive the email</v-expansion-panel-header>
                        <!-- <v-divider></v-divider> -->
                        <v-expansion-panel-content class="py-3">
                            <p class="text-body-1 font-weight-light">We sent a verification email to <b>{{email}}</b>.</p>
                            <p class="text-body-1 font-weight-light">Some email servers take a few minutes to deliver messages, or place automatic messages in a spam folder.</p>
                            <p class="text-body-1 font-weight-light">If it's taking longer than 15 minutes, try using a different email address.</p>
                            <v-btn elevation="4" class="blue white--text" @click="resetVerification">
                                Try again
                            </v-btn>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <!-- display this after user verifies email; the "may take a while" part refers to generating the gateway private key; TODO: remove the "may take a while" comment when we implement the login iframe for the main site -->
        <v-row justify="center" class="my-0 pt-0 pb-10" v-if="(isViewReady && !isAuthenticated && isVerified && !isLoginRequired && !error) || isRegisterQuickUserPending">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <h1 class="text-h5 font-weight-light pt-5 text-center">Your email address is now verified.</h1>
                    <h2 class="text-body-1 font-weight-light pb-5 text-center"><b>{{ email }}</b></h2>
                    <h2 class="text-body-1 font-weight-light pt-5 text-center" v-if="isRegisterQuickUserPending">We are creating your account. This may take a while. Please wait...</h2>
                <!-- <v-row justify="center" class="py-5">
                    <p class="text-h5 font-weight-light my-3 text-center">To manage your account, you'll need the LoginShield app.</p>
                    <p class="text-body-1 font-weight-light my-3 text-center">If you don't already have the app, <a href="/software/" target="_blank">download the app</a> and then return to this page.</p>
                    <p class="text-body-1 font-weight-light my-3 text-center">When you open the LoginShield app the first time, it will prompt you to register. Use the same email address in the LoginShield app to connect it automatically to your free trial account.</p>
                </v-row> -->
            </v-col>
        </v-row>

        <!-- login step is required for verified email that is already linked to an existing account (user could login earlier instead of opt-in) -->
        <v-row justify="center" class="my-0 pt-0 pb-10" v-if="isViewReady && !isAuthenticated && isVerified && isLoginRequired && !error">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-row justify="center" class="py-5">
                    <h1 class="text-h5 font-weight-light">Welcome back!</h1>
                </v-row>
                <v-row justify="center" class="py-5">
                    <p class="text-body-1 font-weight-light my-3 text-center">Log in to continue</p>
                </v-row>
                <v-row justify="center">
                    <v-btn elevation="4" class="blue white--text" @click='onLoginClicked'>Login</v-btn>
                </v-row>
            </v-col>
        </v-row>

        <!-- error message -->
        <v-row justify="center" class="my-0 py-5 pb-10" v-if="error">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <p class="red--text text-center">We cannot process this request.</p>
                <v-alert dense type="error" v-if="isEnterpriseAccountLimit">
                    <span class="text-body-1">Enterprise account limit reached.</span>
                </v-alert>
                <p class="text-center"><a href="/contact/?topic=support">Contact support</a></p>
            </v-col>
        </v-row>
    </AppLayout>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import loginshield from '@/client';
// import OptIn from '@/components/Optin.vue';
// import QuickStartSteps from '@/components/QuickStartSteps.vue';
// import Software from '@/views/Software.vue';
import AppLayout from '@/components/AppLayout.vue';
import { isValidName, isValidEmail } from '@/sdk/input';
import { GatewayClient } from '@cryptium/tigercomet-gateway-browser';

function first(value) {
    if (typeof value === 'string') {
        return value;
    }
    if (typeof value === 'object' && typeof value[0] === 'string') {
        return value[0];
    }
    return null;
}

/*
How this page works:

1. when the user arrives, we check if the user is authenticated or not

2. if the user is not authenticated, we show the opt-in form and a login link

    2a. if the user has an account, the user may login and will be returned to this page after login (3)
    2b. if the user completes the opt-in and email verification with an email address already registered to an existing account, the user will be notified that login is required; the user may login and will be returned to this page after login (3)
    2c. if the user completes the opt-in and email verification with a new email address not registered to any existing account, the user will be automatically registered for a free individual account; the new account will be marked as "quick" to indicate that the user may later get the app and login with the same email address to link their device to the account (once a device is linked to the account, the "quick" mark will be removed and the account will be normal and require a login with LoginShield); the session will be marked as authenticated; the user will be returned to this page (3)

3. if the user is authenticated, we redirect the user to another page for the next step in their process.

FOR WEBAUTHZ (ARRIVED FROM WORDPRESS SITE):
Return to /webauthz/start, which will check if the user already has an enterprise account with a matching realm; if so, that account is automatically selected and displayed, otherwise:

    3a. if the user does NOT have an enterprise account with a matching realm, the user can select an existing enterprise account for the free trial, or choose to create a new enterprise account
    3b. if the user chooses to create a new enterprise account, we will prompt for the company name and then create the account; after creating the new enterprise account, the new account will be automatically selected and the create button will be hidden
    3c. the "continue" button is disabled until an enterprise account is selected

4. when the user taps the "continue" button, if there is no realm id yet we create a new realm in the selected account, then we redirect to the webauthz prompt view with the webauthz parameters, the account id, and the realm id, for the user to confirm the access

5. when the user confirms the access in the webauthz prompt, the server creates a grant token and provides a redirect url (back to the originating application) and we redirect the user there so the application can obtain an access token to manage the realm, upload an icon, and start requesting logins

FOR WORDPRESS QUICK START (ARRIVED FROM /connect/wordpress/#pricing):
Then redirect to a special page in dashboard that will complete the activity:

    3a. if the user does NOT have an enterprise account yet, create one with the selected pricing plan and start the free trial
    3b. if the user chooses to create a new enteprise account, prompt for company name and then create the account; after creating the account, the new account will be automaticallyselected and the create button will be hidden
    3c. the "continue" button is disabled until an enterprise account is selected

Then inform the user to install the plugin in their wordpress site, provide the handbook link for reference.
The difference between this flow and the webauthz flow, is that in webauthz the user starts in wordpress, goes to /webauthz/start, which then redirects here to /register/start, to register and verify the email address or login, and based on a query parameter or interaction info we can also look up the selected plan and mark the new enterprise account with it.

*/

export default {
    components: {
        // OptIn,
        // QuickStartSteps,
        // Software,
        AppLayout,
    },
    data() {
        return {
            interactionId: null, // the webauthz access request interaction
            webauthzRealm: null, // from webauthz access request
            webauthzScope: null, // from webauthz access request
            webauthzClientName: null, // from webauthz access request
            webauthzClientURI: null, // from webauthz access request
            error: false, // true when the webauthz request is invalid
            verificationForm: false, // true when all field inputs are ready to submit
            email: null, // email input from verification form
            name: null, // name input from registration form
            isAgreeToTermsChecked: false, // terms of service checkbox from registration form
            isInteractionReady: false, // true after we either load the interaction, or if there isn't an interaction id
            optinId: null, // true if there is an optin message id
            isOptinReady: false, // true after we check the optin message status, or if there isn't an optin message
            isVerificationPending: false, // email verification was requested but not yet completd by user
            isVerificationReady: false, // email verification email was sent (server returned success status)
            // isViewReady: false, // true after we check session status, see init()
            isVerified: false, // email verification completed (also true when user is authenticated)
            isLoginRequired: false, // true when email is verified and associated to existing account
            isNewAccount: false, // true when user selects to create new account
            targetAccountId: null, // non-null when user selects an existing account
            isNewRealm: false, // true when user selects an account without a matching realm
            targetRealmId: null, // non-null when user selects an account with a matching realm
            isEnterpriseAccountLimit: false,
            isContinueWithCreateAccount: false,
            isRegisterQuickUserPending: false, // true when we register the user and add the first trusted gateway public key
            // isContinueReady: false, // this was not working as a computed property
            nextRoute: null, // this is set when we load the specified interaction and it's not a register user quick interaction, so we set the next route based on the interaction type and state
            nameRules: [
                (v) => isValidName(v) || 'What should we call you? Your first name, nickname, or alias',
            ],
            emailRules: [
                (v) => isValidEmail(v) || 'What email address can we use to reach you?',
            ],
        };
    },
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady, // true after we check if user is authenticated or not (to avoid content flicker while we check)
            session: (state) => state.session,
            user: (state) => state.user,
            accountMap: (state) => state.accountMap,
            accountId: (state) => state.accountId,
        }),
        ...mapGetters({
            enterpriseAccountList: 'enterpriseAccountList', // sorted enterprise accounts
            nonEnterpriseAccountList: 'nonEnterpriseAccountList', // sorted non-enterprise accounts
            currentAccount: 'account', // currently selected account object
        }),
        accountList() {
            return this.enterpriseAccountList.filter((item) => item.permit && item.permit.includes('edit'));
        },
        accountType() {
            return this.currentAccount ? this.currentAccount.type : '';
        },
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isVerificationFormComplete() {
            return this.name && isValidName(this.name) && this.email && isValidEmail(this.email) && this.isAgreeToTermsChecked;
        },
        isContinueReady() {
            return (this.targetAccountId || this.isNewAccount) && (this.targetRealmId || this.isNewRealm);
        },
        isViewReady() {
            return this.isAuthenticatedReady && this.isOptinReady && this.isInteractionReady;
        },
    },
    methods: {
        async loadOptInMessage(optinId) {
            // user arrived here with an existing opt-in message id, so look it up
            // to check if email is already verified
            try {
                this.$store.commit('loading', { loadOptInMessageById: true });
                const message = await loginshield.message.get(optinId);
                console.log(`init: loaded message ${JSON.stringify(message)}`);
                if (typeof message === 'object' && typeof message.verified === 'object' && typeof message.verified.email === 'object' && message.verified.email.length > 0 && typeof message.context === 'object' && message.context.verifyEmailInteractionId) {
                    // email was verified, check if it's this session
                    try {
                        const interaction = await this.$store.dispatch('loadInteraction', message.context.verifyEmailInteractionId);
                        if (typeof interaction === 'object' && interaction !== null) {
                            const { type, state } = interaction;
                            console.log(`init: loaded interaction ${type} with state ${JSON.stringify(state)}`);
                            if (type === 'verify_email' && typeof state === 'object' && state.messageId === optinId) {
                                this.email = state.email;
                                this.isVerified = state.isVerified;
                                this.isLoginRequired = state.isRegistered;
                                if (!this.isLoginRequired) {
                                    // email is verified and it's not an existing user, so register a new "quick" user
                                    this.registerQuickUser({
                                        optinId,
                                        verifyEmailInteractionId: message.context.verifyEmailInteractionId,
                                        nextInteractionId: this.$route.query.i,
                                        intent: this.$route.query.intent,
                                    });
                                }
                            } else {
                                // TODO: what is the interaction type we expect here?
                                this.isVerificationPending = true;
                                this.isVerificationReady = true;
                            }
                        } else {
                            console.log('init: interaction not found');
                            this.error = true;
                        }
                    } catch (err) {
                        console.log('init: cannot load interaction', err);
                        this.error = true;
                    }
                }
            } catch (err) {
                console.log('init: cannot load message', err);
                this.error = true;
            } finally {
                this.optinId = optinId;
                this.isOptinReady = true;
                this.$store.commit('loading', { loadOptInMessageById: false });
            }
        },
        async submitOptIn() {
            const optInRequest = {
                name: this.name,
                email: this.email,
                agreeToTerms: this.isAgreeToTermsChecked,
                subject: 'optin',
                // register: 'enterprise',
                // interactionId,
            };

            // send to server
            try {
                this.$store.commit('loading', { onSubmitVerification: true });
                this.isVerificationPending = true;
                this.isVerificationReady = false;
                const optInResponse = await loginshield.message.create(optInRequest);
                console.log(`submitOptIn: response: ${JSON.stringify(optInResponse)}`);
                if (typeof optInResponse.id === 'string') {
                    return optInResponse;
                }
                console.log('opt-in request failed with response: %o', optInResponse);
                return null;
            } catch (err) {
                console.log('opt-in request failed: %o', err);
                return null;
            } finally {
                this.$store.commit('loading', { onSubmitVerification: false });
            }
        },
        async registerQuickUser({ optinId, verifyEmailInteractionId, nextInteractionId /* intent */ }) {
            console.log('registerQuickUser');
            try {
                this.$store.commit('loading', { registerQuickUser: true });
                this.isRegisterQuickUserPending = true;

                // create the register user quick interaction; this checks eligibility but does not actually register the user yet
                const registerUserInteractionId = await this.createRegisterUserInteraction({
                    messageId: optinId,
                    verifyEmailInteractionId,
                    next: nextInteractionId,
                });
                if (!registerUserInteractionId) {
                    console.error('registerQuickUser: failed to create interaction');
                    this.error = true;
                    return;
                }

                // register the user and create free individual account; if this is successful
                // the server will return { isEdited: true } and it will also mark the session
                // as authenticated
                const isEdited = await this.editRegisterUserInteraction({
                    interactionId: registerUserInteractionId,
                    action: 'register',
                });
                if (!isEdited) {
                    console.error('registerQuickUser: failed to register user');
                    this.error = true;
                    return;
                }

                // reload the user's session and account list
                await this.$store.dispatch('refresh', { progressIndicator: true });

                // create gateway public key, store it as trusted for this user so when they come back in SAME browser and login, they won't get a safety notice
                try {
                    this.$store.commit('loading', { postGatewayPublicKey: true });
                    const { csrfGuardToken } = this.session;
                    const gatewayClient = new GatewayClient({ ...loginshield.options, csrfGuardToken, storage: localStorage });
                    // create a new gateway public key if it's not already created, then store it
                    console.log('Start.vue: posting gateway public key for authenticated user');
                    await gatewayClient.postGatewayPublicKey({ interactionId: registerUserInteractionId });
                } catch (err) {
                    console.log('Start.vue: failed to post gateway public key');
                } finally {
                    this.$store.commit('loading', { postGatewayPublicKey: false });
                }

                // a new user has only the free individual account, so we
                // automatically create the first enterprise account and select it
                // await this.continueWithCreateAccount(); // the UI will update when we set isRegisterQuickUserPending = false, because now isAuthenticated will be true, so they will get the button to create their enterprise account
            } catch (err) {
                console.error('registerQuickUser: error', err);
                this.error = true;
            } finally {
                this.isRegisterQuickUserPending = false;
                this.$store.commit('loading', { registerQuickUser: false });
            }

            // if (intent === 'enterprise_add_realm') {
            //     this.$router.replace({ path: '/enterprise/add-realm', query: { i: this.$route.query.i } });
            // } else {
            //     this.$router.replace({ path: '/interaction', query: { i: this.$route.query.i } });
            // }
            this.$router.replace({ path: '/interaction', query: { i: this.$route.query.i } });
        },
        async createRegisterUserInteraction({ messageId, verifyEmailInteractionId, next: nextInteractionId }) {
            console.log('createRegisterUserInteraction');
            // make sure we have all the required parameters
            if (messageId && nextInteractionId) {
                try {
                    const { id } = await this.$store.dispatch('createInteraction', {
                        type: 'register_user_quick',
                        state: {
                            // linkMessageId: messageId,
                            linkVerifyEmailInteractionId: verifyEmailInteractionId,
                        },
                        next: nextInteractionId,
                        nextRoute: this.nextRoute,
                    });
                    if (id) {
                        console.log('webauthz start: created new interaction');
                        return id;
                    }
                } catch (err) {
                    console.error(err);
                }
            }
            return null;
        },
        async editRegisterUserInteraction({ interactionId, action }) {
            console.log('editRegisterUserInteraction');
            // make sure we have all the required parameters
            if (interactionId && action) {
                try {
                    const { isEdited } = await this.$store.dispatch('editInteraction', {
                        interactionId,
                        message: { action },
                    });
                    return isEdited;
                } catch (err) {
                    console.error(err);
                }
            }
            return false;
        },
        async loadInteraction(interactionId) {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const interactionResponse = await this.$store.dispatch('loadInteraction', interactionId);
                console.log(`register-quick: interaction ${JSON.stringify(interactionResponse)}`);
                const { type, state } = interactionResponse;
                if (type !== 'register_user_quick') {
                    switch (type) {
                    case 'register_user_quick': {
                        this.interactionId = interactionId;
                        break;
                    }
                    case 'webauthz': {
                        const status = { state };
                        if (status === 'start') {
                            this.nextInteractionId = interactionId;
                            this.nextRoute = '/webauthz/start';
                        }
                        break;
                    }
                    default:
                        console.error(`loadInteraction: invalid interaction type: ${type}`);
                        this.error = true;
                        return;
                    }
                }
            } catch (err) {
                console.error(err);
                this.interactionId = null;
                this.error = true;
            } finally {
                this.isInteractionReady = true;
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
        async verifyEmail({ messageId, nextRoute }) {
            console.log('QuickStart.vue: events: opt-in: %o', messageId);
            // request email verification for the email submitted with the optin
            try {
                this.$store.commit('loading', { verifyEmail: true });
                this.isVerificationPending = true;
                this.isVerificationReady = false;
                const verificationResponse = await loginshield.message.verifyEmail({ messageId, nextRoute });
                console.log('QuickStart.vue: verificationResponse: %o', verificationResponse);
                // show directions to check email and click on link
                this.isVerificationReady = true;
                return verificationResponse;
            } catch (err) {
                console.log('QuickStart.vue: optin request failed: %o', err);
                return null;
            } finally {
                this.$store.commit('loading', { verifyEmail: false });
            }
        },
        async onSubmitVerification() {
            console.log(`onSubmitVerification: name=${this.name}, email=${this.email}, agree=${this.isAgreeToTermsChecked}`);
            if (!this.isVerificationFormComplete) {
                return false;
            }
            console.log('onSubmitVerification: form complete');

            // submit the form to store the name and email
            const optInResponse = await this.submitOptIn();
            if (typeof optInResponse !== 'object' || optInResponse === null || typeof optInResponse.id !== 'string') {
                console.error('onSubmitVerification: failed to submit opt-in form');
                this.error = true;
                return false;
            }

            // prepare the forward url where we will continue after email verification, which
            // is this view with current query parameters, plus the optin message id so we can
            // look it up and get the email verification status
            const viewParams = new URLSearchParams(window.location.search);
            viewParams.append('optin_id', optInResponse.id);
            const nextRoute = `${this.$route.path}?${viewParams.toString()}`;
            console.log(`onSubmitVerification: prepared nextRoute: ${nextRoute}`);

            /*
            // create an interaction to register the user (after email verification is complete) before proceding to the webauthz request
            const registerUserInteractionId = await this.createRegisterUserInteraction({ messageId: optInResponse.id, next: webauthzInteractionId });
            console.log(`registration response: ${JSON.stringify(registerUserInteractionId)}`);
            if (typeof registerUserInteractionId !== 'string') {
                console.error('onSubmitVerification: failed to create webauthz interaction');
                this.error = true;
                return false;
            }
            */

            // send verification email and return to this view after verification
            const verificationResponse = await this.verifyEmail({
                messageId: optInResponse.id,
                // next: registerUserInteractionId,
                nextRoute,
            });
            console.log(`verification response: ${JSON.stringify(verificationResponse)}`);
            if (typeof verificationResponse !== 'object' || verificationResponse === null || typeof verificationResponse.token !== 'object' || typeof verificationResponse.token.id !== 'string') {
                console.error('onSubmitVerification: failed to request email verification');
                this.error = true;
                return false;
            }

            /*
            // when user follows link in verification email, the interaction system will return them to the webauthz interactionId created above
            // and that interaction will be updated with the verified email address information and the opt-in message information (name, email)
            const isEdited = await loginshield.interaction.edit(interactionId, {
                action: 'edit',
                linkVerifyEmailInteractionId: verificationResponse.token.interactionId,
            });
            if (!isEdited) {
                console.error('onSubmitVerification: failed to update webauthz interaction with verify email interaction id');
                this.error = true;
                return false;
            }
            */

            return true; // all actions are successful
        },
        validateVerificationForm() {
            /*
            if (this.$refs.verificationFormRef.validate()) {
                this.onSubmitVerification();
            }
            */
            if (this.name && isValidName(this.name) && this.email && isValidEmail(this.email) && this.isAgreeToTermsChecked) {
                this.onSubmitVerification();
            }
        },
        resetVerification() {
            // reset the view to the opt-in form, in case user needs to edit email address
            this.isVerified = false;
            this.isVerificationPending = false;
            this.isVerificationReady = false;
            this.$nextTick(() => this.$refs.emailInput.focus());
        },
        async onLoginClicked() {
            // show the login page, and capture the current page with query parameters so we can return user here after login
            this.$router.push({ path: '/login', query: { from: this.$route.fullPath } });
        },
    },
    watch: {
        // isAuthenticatedReady(value, oldValue) {
        //     console.log('start.vue: isAuthenticatedReady changed from %o to %o', oldValue, value);
        //     // only call init again if ready changed from false to true after mounted()
        //     if (value && !oldValue) {
        //         this.init();
        //     }
        // },
        // '$route.query.step': function onChangeStep() {
        //     this.step = this.$route.query.step;
        // },
        isViewReady(value) {
            if (value) {
                // we don't use the "autofocus" property of the name input because when
                // the user needs to reset the form to edit the email address, we want
                // to be able to set the focus on the email field instead of the name
                // field
                if (!this.isAuthenticated) {
                    this.$nextTick(() => this.$refs.nameInput.focus());
                }
                if (this.isAuthenticated) {
                    const interactionId = first(this.$route.query.i);
                    if (interactionId) {
                        // if user is authenticated and already has an interaction, continue to that interaction
                        this.$router.replace({ path: '/interaction', query: { i: interactionId } });
                        return;
                    }
                    const intent = first(this.$route.query.intent);
                    if (intent) {
                        switch (intent) {
                        case 'enterprise_add_realm':
                            // an authenticated user arriving with this intent probably came from the pricing page,
                            // and we need to redirect to the account page where they can add a realm
                            // the planId parameter is optional, it will be used to pre-select the plan in the
                            // add-realm page, but if not provided or invalid the full selection will be available there;
                            // the tag is also optional, e.g. 'wordpress', to help the add-realm page show relevant plans first.
                            this.$router.replace({
                                path: '/enterprise/add-realm',
                                query: {
                                    tag: this.$route.query.tag,
                                    planId: this.$route.query.planId,
                                },
                            });
                            return;
                        default:
                            console.log(`unknown intent ${intent}`);
                            // do nothing, show the quick registration form
                        }
                    }
                    console.log('authenticated user with no interaction or known intent');
                    // do nothing, show the quick registration form
                }
            }
        },
    },

    mounted() {
        const interactionId = first(this.$route.query.i);
        if (interactionId) {
            this.loadInteraction(interactionId);
        } else {
            this.isInteractionReady = true;
        }

        // if the query has multiple optin_id parameters, use the first one
        const optinId = first(this.$route.query.optin_id);
        if (optinId) {
            this.loadOptInMessage(optinId);
        } else {
            this.isOptinReady = true;
        }

        // if (this.isAuthenticatedReady) {
        //     this.init();
        // }
    },
};
</script>
